// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import some from 'lodash/some';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Models
import { DEFAULT } from 'app/shared/models/Filter';

// Components
import Button from 'app/shared/core/Button';
import Center from 'app/shared/modules/Center';
import Input from 'app/shared/core/Input';
import Linker from 'app/shared/modules/Linker';
import PromptCasl from 'app/shared/modules/auth/PromptCasl';
import PopupModal from 'app/shared/modules/popups/PopupModal';
import ProTipAlert from 'app/shared/modules/ProTipAlert';
import Radio from 'app/shared/core/Radio';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';
import Title from 'app/shared/modules/Title';

// Misc / Utils
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import constants from 'app/shared/constants/ConstantsBundle';
import { filterUtils_getFilterLabels } from 'app/shared/utils/filterUtils';
import isEqual from 'lodash/isEqual';
import loginUtils from 'app/shared/utils/loginUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import 'app/shared/modules/user-search/CreateSearchAlertModal.scss';
import validator from 'app/shared/utils/validator';
import { TrackCreateSearchAlert } from 'app/shared/models/Clickstream/SearchAlertEvents';

class CreateSearchAlertModal extends React.Component {
  static propTypes = {
    area: PropTypes.object,
    email: PropTypes.string,
    onHidePopup: PropTypes.func,
    triggerLocation: PropTypes.string,
    triggerObject: PropTypes.string,
  };

  static defaultProps = {
    area: {},
    email: '',
    onHidePopup: () => {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Reset error message saved in state once a user has logged in!
    if (nextProps.isLoggedIn && prevState.error) {
      return {
        error: '',
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const { area, shouldUseAreaBoundary, email, totalListings, filter } = this.props;
    const initialEmailFrequency = totalListings > constants.LISTINGS_THRESHOLD ? 'daily' : 'hourly';

    let searchName = area.fullName;
    if (!shouldUseAreaBoundary && searchName) {
      searchName = searchName.split(', ')[0];
      searchName += ' Area';
    }

    const shouldDisplayProTip = isEqual(
      {
        bedrooms: filter.bedrooms,
        lowPrice: filter.price.min,
        highPrice: filter.price.max,
      },
      {
        bedrooms: DEFAULT.bedrooms,
        lowPrice: DEFAULT.price.min,
        highPrice: DEFAULT.price.max,
      },
    );

    this.state = {
      searchName,
      email: email || '',
      frequency: initialEmailFrequency,
      error: '',
      displayProTip: shouldDisplayProTip,
      displayPromptCasl: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(NotificationActions.notifications.getSettings());
  }

  setInputState = (stateKey, e) => {
    const obj = {};

    obj[stateKey] = e.target.value;
    this.setState(obj);
  };

  setEmailFrequency = (e) => {
    const frequency = e.target.value;
    this.setState({ frequency });
  };

  handleValidateBeforeCreatingAlert = (e) => {
    e.preventDefault();
    const { email, searchName, frequency } = this.state;
    if (!email) {
      this.setState({
        error: 'Please enter your email.',
      });
    } else if (!searchName) {
      this.setState({
        error: 'Enter a name for your search.',
      });
    } else if (!validator.email(email).isValid) {
      this.setState({
        error: 'Please enter a valid email.',
      });
    } else if (email && searchName && frequency) {
      this.handleCreateSearchAlert();
    } else {
      this.setState({
        error: 'An error has occurred, try again',
      });
    }
  };

  handleCreateSearchAlert = () => {
    const { props, state } = this;
    const {
      area,
      countryCode,
      dispatch,
      onHidePopup,
      pathname,
      savedSearchPreferences,
      shouldUseAreaBoundary,
      triggerLocation = 'search_results',
      triggerObject = 'search_results|alerts_modal',
    } = props;
    const { searchName, email, frequency } = state;

    const useMapBounds = routeUtils.isAreaUrl(pathname) || routeUtils.isPadOrBuildingUrl(pathname);

    dispatch(
      UserSearchActions.search.saved.create(
        searchName,
        email,
        frequency,
        useMapBounds,
        shouldUseAreaBoundary ? area.id : null,
      ),
    ).then((result) => {
      if (result.loggedIn) {
        dispatch(
          analyticsEvent(gaEvents.SEARCH_ALERT_CREATE, {
            newLaneEvent: TrackCreateSearchAlert({
              triggerLocation,
              triggerObject,
            }),
          }),
        );
        dispatch(UserSearchActions.getSavedSearches());

        if (loginUtils.isCanadianUser(countryCode, email)) {
          this.setState({
            displayPromptCasl: true,
          });
        } else {
          onHidePopup();
        }

        if (!some(savedSearchPreferences)) {
          dispatch(AppActions.toggleOverlay('optInSearchAlerts', true));
        } else {
          dispatch(
            AppActions.toggleNotificationMessage({
              content: 'Success! Search alert created.',
            }),
          );
        }
      } else {
        this.setState({
          error: 'You have a hotpads account! Please log in first.',
        });
      }
    });
  };

  yieldTrackAndShowPopup = yieldCallback(() => {
    this.props.dispatch(analyticsEvent(gaEvents.SEARCH_ALERT_LOG_IN));
    this.props.dispatch(AppActions.showPopupModal('PromptLogin'));
  });

  handleLogin = () => {
    this.yieldTrackAndShowPopup();
  };

  render() {
    const { area, email, filter, onHidePopup, shouldUseAreaBoundary } = this.props;
    const { frequency, error, displayProTip, searchName, displayPromptCasl, email: statefullEmail } = this.state;
    const filterSummary = filterUtils_getFilterLabels(filter);

    if (displayPromptCasl) {
      return (
        <PopupModal onHidePopup={onHidePopup} paddingInline="2x">
          <div className="CreateSearchAlertModal">
            <PromptCasl onHidePopup={onHidePopup} email={statefullEmail} showBreadcrumb={false} />
          </div>
        </PopupModal>
      );
    }

    return (
      <PopupModal onHidePopup={onHidePopup} aria-labelledby="dialog-title" position="top" paddingInline="2x">
        <div className="CreateSearchAlertModal">
          <Title id="dialog-title" size="lg">
            Create a search alert
          </Title>
          <div className="CreateSearchAlertModal-subtitle">
            <Text>Listings come and go quickly. Get alerts of new and updated homes!</Text>
          </div>
          <form>
            <Row>
              <Input
                id="alert-name"
                label="Alert name"
                maxLength={115}
                onChange={this.setInputState.bind(null, 'searchName')}
                placeholder="Name your search"
                required
                type="text"
                value={searchName}
              />
            </Row>
            {!email && (
              <Row>
                <Input
                  label="Your email"
                  placeholder="Enter your email address..."
                  onChange={this.setInputState.bind(null, 'email')}
                  required
                  type="text"
                  value={statefullEmail}
                />
              </Row>
            )}
            <Row className="CreateSearchAlertModal-filter-summary-text">
              <h2 className="CreateSearchAlertModal-filter-summary-title">Filter summary</h2>
              <div className="search-type-area">
                <span>Listings </span>
                {shouldUseAreaBoundary ? <span>within {area.name}</span> : <span>near {area.name}</span>}
              </div>
              <span className="filter-summary">{filterSummary}</span>
            </Row>
            {displayProTip && <ProTipAlert />}
            <div className="CreateSearchAlertModal-notification-frequency">
              <div id="notification-frequency-group" className="CreateSearchAlertModal-notification-frequency-title">
                Notification frequency
              </div>
              <div className="email-options" role="radiogroup" aria-labelledby="notification-frequency-group">
                <Radio
                  ariaLabel="instant"
                  id="hourly"
                  isChecked={frequency === 'hourly'}
                  name="emailFrequency"
                  onChange={this.setEmailFrequency}
                  value="hourly"
                >
                  Instant
                </Radio>
                <Radio
                  ariaLabel="daily"
                  id="daily"
                  isChecked={frequency === 'daily'}
                  name="emailFrequency"
                  onChange={this.setEmailFrequency}
                  value="daily"
                >
                  Daily
                </Radio>
              </div>
            </div>
            <Row>
              <Center>
                <Button full btnType="primary" type="submit" onClick={this.handleValidateBeforeCreatingAlert}>
                  Create alert
                </Button>
              </Center>
            </Row>
            {error && (
              <Row>
                <p className="CreateSearchAlertModal-error">{error}</p>
              </Row>
            )}
            {!email && (
              <Row>
                <p className="CreateSearchAlertModal-login">
                  <span>Already have an account?</span>
                  <Linker onClick={this.handleLogin}> Log in </Linker>
                </p>
              </Row>
            )}
          </form>
        </div>
      </PopupModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    area: state.area.area,
    countryCode: state.geolocation.area.countryCode,
    email: state.user.info.email,
    filter: state.filter,
    isLoggedIn: state.user.loggedIn,
    pathname: ownProps.location.pathname,
    savedSearchPreferences: state.user.savedSearchPreferences,
    shouldUseAreaBoundary: queryUtils.parse(ownProps.location.search).border !== false,
    totalListings: state.listings.totalListings,
  };
};

export default withRouter(connect(mapStateToProps)(CreateSearchAlertModal));
