// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';

import './style.scss';
import FooterNav from 'app/shared/modules/navigation/footer/FooterNav';

import pathsManifest from 'app/shared/utils/pathsManifest';
import states from 'app/shared/utils/states.json';

const StatePageUrls = states.map((state) => {
  return `/${kebabCase(state.name)}`;
});

import {
  AboutUsPage,
  AlertsView,
  AnalyticsPage,
  EditSearchFrequencyPage,
  FeedbackSuccessPage,
  ForgotPasswordPage,
  IpCheckPage,
  ListYourRentalPage,
  LoginPage,
  LogoutPage,
  MobileAppsPage,
  MyHubPage,
  MyListPage,
  NetPromoterScorePage,
  NotFoundPage,
  PropertyReviewGuidelinesPage,
  ReactivateSearchesPage,
  ReportListingAsUnavailablePage,
  SitemapPage,
  SsrTracePage,
  StatePage,
  UnsubscribePage,
  UnsubscribePropertyUpdatePage,
  UnsubscribeSearchesPage,
  UpdatePasswordPage,
  UserDashboardTemplate,
} from 'app/shared/LoadablePages';

const UserDashboardTemplatePaths = [
  pathsManifest.ViewedItemsPage,
  pathsManifest.HiddenItemsPage,
  pathsManifest.UserEditPage,
  pathsManifest.UserRenterProfilePage,
  pathsManifest.UserDashboardPage,
  pathsManifest.UserSettingsPage,
  pathsManifest.CreatePasswordPage,
];

class HeaderFooterTemplate extends Component {
  render() {
    return (
      <div className="HeaderFooterTemplate">
        <div className="HeaderFooterTemplate-children">
          <Switch>
            <Route component={AboutUsPage} path={pathsManifest.AboutUsPage} />
            <Route component={ListYourRentalPage} path={pathsManifest.ListYourRentalPage} />
            <Route component={AlertsView} path={pathsManifest.SavedSearchPage} />
            <Route component={MyHubPage} path={pathsManifest.MyHubPage} />
            <Route component={MyListPage} path={pathsManifest.MyListPage} />
            <Route component={UserDashboardTemplate} path={UserDashboardTemplatePaths} />
            <Route component={UpdatePasswordPage} path={pathsManifest.UpdatePasswordPage} />
            <Route component={AnalyticsPage} path={pathsManifest.AnalyticsPage} />
            <Route component={SsrTracePage} path={pathsManifest.SsrTracePage} />
            <Route component={SitemapPage} path={pathsManifest.SitemapPage} />
            {StatePageUrls.map((state) => {
              return <Route component={StatePage} path={`${state}`} key={`${state}`} />;
            })}
            <Route component={FeedbackSuccessPage} path={pathsManifest.FeedbackSuccessPage} />
            <Route component={PropertyReviewGuidelinesPage} path={pathsManifest.PropertyReviewGuidelinesPage} />
            <Route component={LoginPage} path={pathsManifest.LoginPage} />
            <Route component={LogoutPage} path={pathsManifest.LogoutPage} />
            <Route component={MobileAppsPage} path={pathsManifest.MobileAppsPage} />
            <Route component={UnsubscribeSearchesPage} path={pathsManifest.UnsubscribeSearchesPage} />
            <Route component={UnsubscribePropertyUpdatePage} path={pathsManifest.UnsubscribePropertyUpdatePage} />
            <Route component={UnsubscribePage} path={pathsManifest.UnsubscribePage} />
            <Route component={ReportListingAsUnavailablePage} path={pathsManifest.ReportListingAsUnavailablePage} />
            <Route component={EditSearchFrequencyPage} path={pathsManifest.EditSearchFrequencyPage} />
            <Route component={ReactivateSearchesPage} path={pathsManifest.ReactivateSearchesPage} />
            <Route component={NetPromoterScorePage} path={pathsManifest.NetPromoterScorePage} />
            <Route component={ForgotPasswordPage} path={pathsManifest.ForgotPasswordPage} />
            <Route component={IpCheckPage} path={pathsManifest.IpCheckPage} />
            <Route component={NotFoundPage} path="*" />
          </Switch>
        </div>
        <FooterNav />
      </div>
    );
  }
}

export default connect(null, null, null, { pure: false })(HeaderFooterTemplate);
