// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Text from 'app/shared/core/Text';
import Row from 'app/shared/core/Row';
import './style.scss';

class ProTipAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ProTipAlert">
        <Row>
          <Text size="sm" className="ProTipAlert-text">
            Pro-tip! Set a <span className="ProTipAlert-text-link">price</span> and{' '}
            <span className="ProTipAlert-text-link">bed filter</span> to better personalize your results
          </Text>
        </Row>
      </div>
    );
  }
}

export default ProTipAlert;
