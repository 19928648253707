import type { ClickstreamEvent } from 'app/types';
import { Envelope } from './Envelope';

type TriggerLocation = 'post_contact_popup' | 'search_results';
type TriggerObject = 'search_results|alerts_modal' | 'post_contact_popup|alerts_modal';

export const TrackCreateSearchAlert = ({
  triggerLocation,
  triggerObject,
}: {
  triggerLocation: TriggerLocation;
  triggerObject: TriggerObject;
}) => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5834',
      eventVersion: '2',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'button_to_create_search_alert',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'create_search_alert',
    },
  };
  return csPayload;
};

export const TrackPostContactPopupView = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5930',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'post_contact_popup',
      trigger_source_nm: 'post_contact_popup',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
  return csPayload;
};

export const TrackOpenSearchAlertModal = () => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5832',
      eventVersion: '1',
      templateId: '171',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'button_to_open_search_alert_modal',
      trigger_object_nm: 'filter_panel',
    },
    semantic: {
      semantic_event_nm: 'open_search_alert_modal',
    },
  };
  return csPayload;
};
